import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails
} from './AccordionMuiTheme';
import { Link, List, ListItem } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import PrimaryTitles from './PrimaryTitle';
import { useTranslation } from 'react-i18next';
import { getLocalizedPath } from '../../../i18n/functions';

const useStyles = makeStyles(theme => ({
  aboutMainDiv: {
    padding: theme.spacing(3),
    minHeight: 280,
    background:
      'url(https://cdn1.acedms.com/photos/images/droomCredit/v2/about-bg.png) center top',
    '& .Credit-MuiAccordionSummary-expandIcon.Credit-Mui-expanded': {
      transform: 'rotate(90deg)',
      top: 2
    }
  }
}));

const fallBackTranlations = {
  'home-h1-p1':
    'A vehicle loan is a type of financing option that allows you to borrow money from a lender to purchase a vehicle, such as a car, motorcycle, or even a commercial vehicle. The loan is typically repaid in monthly installments, along with interest, over a predefined period. Droom Credit provides a seamless and instant way to get vehicle loans, offering a paperless, quick, and transparent process. By leveraging advanced algorithms, Droom Credit ensures a hassle-free experience, enabling buyers to achieve their dreams of owning a vehicle without the cumbersome paperwork and long approval times.',
  'home-h2-p1':
    'Easy Ownership: A vehicle loan makes it possible for you to own your desired vehicle without paying the entire cost upfront. You can enjoy the vehicle now and pay for it overtime.',
  'home-h2-p2':
    'Instant Approval: Droom Credit offers instant loan approval through a simple, paperless process. Forget the long waiting periods and endless paperwork.',
  'home-h2-p3':
    'Flexible Repayment Options: Vehicle loans come with flexible repayment tenures, ensuring that you can choose the most convenient schedule to suit your financial situation.',
  'home-h2-p4':
    'Low-Interest Rates: By leveraging Droom Credit’s smart financing options, you can benefit from competitive interest rates that keep your monthly payments manageable.',
  'home-h2-p5':
    'Data-Driven Transparency: With Droom Credit, you get access to a transparent loan process, where all terms and conditions are clearly communicated upfront, so there are no hidden surprises.',
  'home-h3-p1':
    'The interest rate on a vehicle loan can vary based on factors such as your credit score, loan amount, repayment term, and the type of vehicle being financed. Droom Credit provides competitive interest rates designed to make the loan process as affordable as possible. With Droom Credit’s data-driven and transparent approach, you can be assured of getting the best possible financing option, tailored to your specific needs. Thanks to the use of advanced algorithms, the approval process is quick, allowing you to secure a loan at the most favorable rates available.',
  'home-h4-p1': `When it comes to planning your vehicle loan, knowing exactly how it will impact your monthly budget is crucial. That's where our Auto Loan EMI Calculator comes in! This powerful tool helps you calculate the exact cost of your loan, including the interest rate and tenure, so you can plan your finances without any surprises.`,
  'home-h4-p2':
    'Our EMI calculator is designed with ease in mind. It features three adjustable sliders for down payment, repayment tenure, and interest rate – all customized to fit your specific loan conditions.',
  'home-h4-p3': 
    `Simply enter the price of the vehicle you're looking to buy, adjust the sliders according to your loan preferences, and click on "Calculate Your EMI". Voila! You’ll instantly see the precise EMI amount you'll need to pay.`,
  'home-h4-p4':
    'It’s one of the easiest and most intuitive vehicle loan calculators available online, making it the perfect tool to help you make informed decisions about your auto loan. Start planning your dream vehicle today!.'
};

export default function AboutLoans() {
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const classes = useStyles();
  const { t, i18n } = useTranslation(['app', 'common']);
  return (
    <div className={classes.aboutMainDiv}>
      <div className="container">
        <PrimaryTitles
          name={t('common:About Vehicle Loans', 'About Vehicle Loans')}
        ></PrimaryTitles>
        <Accordion
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
        >
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            <Typography component="h3" variant="subtitle1">
              {t('home-h1', 'What is a Vehicle Loan?')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2">
              {t('home-h1-p1', fallBackTranlations['home-h1-p1'])}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel2'}
          onChange={handleChange('panel2')}
        >
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel2d-content"
            id="panel2d-header"
          >
            <Typography component="h3" variant="subtitle1">
              {t('home-h2', 'Benefits Of Vehicle Loan')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2">
              <List>
                <ListItem>
                  {t('home-h2-p1', fallBackTranlations['home-h2-p1'])}
                </ListItem>
                <ListItem>
                  {t('home-h2-p2', fallBackTranlations['home-h2-p2'])}
                </ListItem>
                <ListItem>
                  {t('home-h2-p3', fallBackTranlations['home-h2-p3'])}
                </ListItem>
                <ListItem>
                  {t('home-h2-p4', fallBackTranlations['home-h2-p4'])}
                </ListItem>
                <ListItem>
                  {t('home-h2-p5', fallBackTranlations['home-h2-p5'])}
                </ListItem>
              </List>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel3'}
          onChange={handleChange('panel3')}
        >
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel3d-content"
            id="panel3d-header"
          >
            <Typography component="h3" variant="subtitle1">
              {t('home-h3', 'Vehicle Loan Interest Rate')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2">
              {t('home-h3-p1', fallBackTranlations['home-h3-p1'])}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel4'}
          onChange={handleChange('panel4')}
          className={classes.mainPanel}
        >
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <Typography component="h3" variant="subtitle1">
              {t('home-h4', 'Vehicle Loan Calculator')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2">
              <List>
                <ListItem>
                  {t('home-h4-p1', fallBackTranlations['home-h4-p1'])}
                </ListItem>
                <ListItem>
                  {t('home-h4-p2', fallBackTranlations['home-h4-p2'])}
                </ListItem>
                <ListItem>
                  {t('home-h4-p3', fallBackTranlations['home-h4-p3'])}
                </ListItem>
                <ListItem>
                  {t('home-h4-p4', fallBackTranlations['home-h4-p4'])}
                </ListItem>
              </List>

              {/* <Link
                color="primary"
                href={getLocalizedPath('emi-calculator', i18n)}
              >
                {` ${t('common:EMI Calculator', 'EMI Calculator')} `}
              </Link> */}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}
