import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PrimaryTitle from './PrimaryTitle';
import { CardContent, CardMedia, Divider, Typography } from '@material-ui/core';
import 'owl.carousel/dist/assets/owl.carousel.css';
import './CustomCarousel.css';
import { useTranslation } from 'react-i18next';
import OwlCarouselWrapper from './OwlCarouselWrapper';

const useStyles = makeStyles(theme => ({
  header: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    '&:before': {
      content: ` url('https://cdn1.acedms.com/photos/images/droomCredit/v2/quote-1.png') `,
      position: 'absolute',
      left: 10,
      top: 10
    },
    [theme.breakpoints.down(767)]: {
      '&:before': {
        display: 'none'
      }
    }
  },
  cover: {
    width: theme.spacing(4.2),
    height: theme.spacing(4.8)
  },
  content: {
    display: 'flex',
    flexDirection: 'column'
  },
  titleText: {
    fontWeight: 600,
    textTransform: 'uppercase'
  },
  centerText: {
    marginBottom: theme.spacing(2),
    textAlign: 'center'
  },
  avatar: {
    display: 'flex',
    marginTop: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'center'
  },
  innerSection: {
    position: 'relative',
    background: '#fff',
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down(767)]: {
      padding: theme.spacing(2.5),
      '&:before': {
        content: ` url('https://cdn1.acedms.com/photos/images/droomCredit/v2/quote-1.png') `,
        position: 'absolute',
        left: 10,
        top: -40
      }
    }
  }
}));
const options = {
  items: 1,
  nav: false,
  loop: true,
  autoplay: false,
  lazyLoad: true,
  margin: 10,
  dots: true
};

export default function Testimonials() {
  const { t, i18n } = useTranslation('app');
  const classes = useStyles();
  return (
    <div className="wrapper">
      <div className="container">
        <PrimaryTitle
          name={t('testimonial-heading', 'Testimonials')}
        ></PrimaryTitle>
        <div className={classes.innerSection}>
          <OwlCarouselWrapper className="owl-theme" {...options}>
            <div>
              <div className={classes.header}>
                <CardContent className={classes.content}></CardContent>
              </div>
              <CardContent className={classes.centerText}>
                <Typography variant="body2">
                  {t(
                    'testimonial-p1',
                    'Applying for loans used to be a nightmare, but Droom Credit made it quick and effortless. The paperless process and instant approval made it incredibly convenient. Highly recommended for anyone looking for hassle-free financing!.'
                  )}
                </Typography>
              </CardContent>
              <Divider />
              <div className={classes.avatar}>
                <CardMedia
                  component="img"
                  alt="Rajeeve kapoor"
                  height="140"
                  image="https://cdn1.acedms.com/photos/images/droomCredit/v2/rajeeve.png"
                  title="Contemplative Reptile"
                  className="avatarImg"
                />
                <CardContent className="avatarDetails">
                  <Typography variant="caption" display="block" gutterBottom>
                    {t(
                      'Rajeev Kapoor, IT Consultant',
                      'Rajeev Kapoor, IT Consultant'
                    )}
                  </Typography>
                </CardContent>
              </div>
            </div>
            <div>
              <div className={classes.header}>
                <CardContent className={classes.content}></CardContent>
              </div>
              <CardContent className={classes.centerText}>
                <Typography variant="body2">
                  {t(
                    'testimonial-p2',
                    'I was hesitant about loans before, but Droom Credit changed my perspective. The easy, transparent, and fast approval process helped me achieve my dream of buying a car. I’m so grateful!.'
                  )}
                </Typography>
              </CardContent>
              <Divider />
              <div className={classes.avatar}>
                <CardMedia
                  component="img"
                  alt="Priya Desai"
                  height="140"
                  image="https://cdn1.acedms.com/photos/images/droomCredit/v2/priya.png"
                  title="Contemplative Reptile"
                  className="avatarImg"
                />
                <CardContent className="avatarDetails">
                  <Typography variant="caption" display="block" gutterBottom>
                    {t(
                      'Priya Desai, Small Business Owner',
                      'Priya Desai, Small Business Owner'
                    )}
                  </Typography>
                </CardContent>
              </div>
            </div>
            <div>
              <div className={classes.header}>
                <CardContent className={classes.content}></CardContent>
              </div>
              <CardContent className={classes.centerText}>
                <Typography variant="body2">
                  {t(
                    'testimonial-p3',
                    'The traditional loan process is always such a headache. But Droom Credit’s instant, data-driven approach was a game-changer. No paperwork, no endless waiting – just quick approval. I’m glad I used it!.'
                  )}
                </Typography>
              </CardContent>
              <Divider />
              <div className={classes.avatar}>
                <CardMedia
                  component="img"
                  alt=" vikas"
                  height="140"
                  image="https://cdn1.acedms.com/photos/images/droomCredit/v2/vikas.png"
                  title="Contemplative Reptile"
                  className="avatarImg"
                />
                <CardContent className="avatarDetails">
                  <Typography variant="caption" display="block" gutterBottom>
                    {t(
                      'Vikas Mehra, Sales Manager at Tech Solutions',
                      'Vikas Mehra, Sales Manager at Tech Solutions'
                    )}
                  </Typography>
                </CardContent>
              </div>
            </div>
            <div>
              <div className={classes.header}>
                <CardContent className={classes.content}></CardContent>
              </div>
              <CardContent className={classes.centerText}>
                <Typography variant="body2">
                  {t(
                    'testimonial-p4',
                    'Droom Credit made getting a loan so simple! The entire process was transparent and fast, without the usual paperwork hassle. I was able to get approved instantly and achieve my goals effortlessly.'
                  )}
                </Typography>
              </CardContent>
              <Divider />
              <div className={classes.avatar}>
                <CardMedia
                  component="img"
                  alt="Shalini Rao"
                  height="140"
                  image="https://cdn1.acedms.com/photos/images/droomCredit/v2/shalini-rao.png"
                  title="Contemplative Reptile"
                  className="avatarImg"
                />
                <CardContent className="avatarDetails">
                  <Typography variant="caption" display="block" gutterBottom>
                    {t(
                      'Shalini Rao, Freelance Writer',
                      'Shalini Rao, Freelance Writer'
                    )}
                  </Typography>
                </CardContent>
              </div>
            </div>
          </OwlCarouselWrapper>
        </div>
      </div>
    </div>
  );
}
